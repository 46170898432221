function toggleLoader() {
    $(".app-loader-container").toggleClass("hidden");

    if ($(this).hasClass("hidden")) {
        $(".app-loader").data('state', 'hidden');
    } else {
        $(".app-loader").data('state', 'visible');
    }
}

function showLoader() {
    $(".app-loader-container").removeClass("hidden");
    $(".app-loader").data('state', 'visible');
}

function hideLoader() {
    $(".app-loader-container").addClass("hidden");
    $(".app-loader").data('state', 'hidden');
}
